<template>
  <error-message
    v-if="name && showError"
    v-slot="{ message: error }"
    :name="name"
    class="mt-4 flex gap-2 text-xs leading-xs text-error"
    as="p"
  >
    <s-icon size="xl" icon="ic-v2-state-warning-circle-fill" class="mt-[.1rem]" />
    <safe-html :html="$t(getError(error).message, data)" />
  </error-message>
</template>
<script setup lang="ts">
import SafeHtml from '@/components/common/safe-html.vue';

withDefaults(defineProps<{ name: string; data?: any; showError?: boolean }>(), {
  data: {},
  showError: true
});
const getError = (error: string) => {
  return JSON.parse(error);
};
</script>
